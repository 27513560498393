import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../../helpers/constants";
import { graphql, useStaticQuery } from "gatsby";
import PointIcon from "../../../../assets/images/vector/components/common/group-copy.svg";
import JpStandardFormBlockContainer from "../../../../containers/common/forms/JpStandardFormBlockContainer";
import FastImage from "../../../../containers/common/FastImage";
import "./index.scss";

const HomeIntro = (props) => {
  const { isSecondStateCollectEmail, setIsSecondStateCollectEmail, onSuccess } =
    props;

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { regex: "/jp-pro-homeIntro/" }, ext: { eq: ".png" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  return (
    <section className="PgJP-HomeIntro">
      <div className="container">
        <div className="PgJP-HomeIntro-row">
          <div className="PgJP-HomeIntro-info">
            <div className="PgJP-HomeIntro__title">
              <h1>AMZScout PRO拡張機能</h1>
              <p>
                <span>製品のポテンシャルと</span> 競争のレベルを確認してください
              </p>
            </div>
            <ul className="PgJP-HomeIntro-points">
              <li>
                <img src={PointIcon} alt="" />
                <p>堅実な売上統計に基づいて収益性の高い製品を見つける</p>
              </li>
              <li>
                <img src={PointIcon} alt="" />
                <p>既存のニッチで新しい販売者として期待できる収入を見積もる</p>
              </li>
              <li>
                <img src={PointIcon} alt="" />
                <p>
                  より簡単な配送で製品の販売を開始できるように、確認済みの地元およびグローバルサプライヤを見つけてください。
                </p>
              </li>
            </ul>
            <JpStandardFormBlockContainer
              pageFormState={isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              buttonTextFirstState="無料でPRO拡張機能をトライ"
              buttonTextSecondState="無料でPRO拡張機能をトライ"
              page={PAGES.JP_PRO}
              customClass="PgJP-HomeIntro-emailCollect"
              placeholder="メールアドレスを入力して、無料トライアルを開始してください。"
              onSuccess={onSuccess}
              isIntro
            />
          </div>
          <div className="PgJP-HomeIntro-screen">
            <FastImage
              imageData={data}
              wrapImageClassName="PgJP-HomeIntro-screen__img"
              imageAltName="home image"
              isAdaptive
              isWithRatio
              isFullBreaks
              defaultName="jp-pro-homeIntro"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
};
